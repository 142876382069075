var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.errorLoading)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.errorLoading))]):_vm._e(),_c('loading-modal',{model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}),(_vm.errorSaving)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.errorSaving))]):_vm._e(),_c('keyboard-shortcuts',{on:{"previous":_vm.onPrev,"next":_vm.onNext,"save":_vm.saveInMemoryLabels}}),_c('div',{staticClass:"annotation-view"},[(!!_vm.activeImageSrc && !!_vm.selectedLabelByTask)?_c('annotation-view',{attrs:{"imageSrc":_vm.activeImageSrc,"config":_vm.uiParameters,"annotations":_vm.labelsFromServer,"selectedLabelByTask":_vm.selectedLabelByTask,"saveStatus":_vm.saveStatus},on:{"next":_vm.onNext,"prev":_vm.onPrev,"input":_vm.updateSelectedLabels}}):_vm._e(),_c('div',{staticClass:"fab-buttons",attrs:{"absolute":""}},[_c('tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"fab-button px-2",attrs:{"dark":"","small":"","color":"indigo","aria-label":"Next"},on:{"click":function($event){$event.stopPropagation();return _vm.onNext($event)}}},'v-btn',attrs,false),tooltip),[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)]}}])},[_c('span',[_vm._v("Next ("),_c('kbd',[_vm._v("d")]),_vm._v(")")])]),_c('tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"fab-button px-2",attrs:{"dark":"","small":"","color":"indigo","aria-label":"Previous"},on:{"click":function($event){$event.stopPropagation();return _vm.onPrev($event)}}},'v-btn',attrs,false),tooltip),[_c('v-icon',[_vm._v("mdi-chevron-left")])],1)]}}])},[_c('span',[_vm._v("Previous ("),_c('kbd',[_vm._v("a")]),_vm._v(")")])]),_c('tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"fab-button px-2",class:_vm.hasUnsavedLabels ? 'accent' : 'save-btn--non-active',attrs:{"dark":"","small":"","aria-label":"Save","disabled":_vm.saving},on:{"click":function($event){$event.stopPropagation();return _vm.saveInMemoryLabels($event)}}},'v-btn',attrs,false),tooltip),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-save")])],1)]}}])},[_c('span',[_vm._v("Save ("),_c('kbd',[_vm._v("s")]),_vm._v(")")])]),_vm._l((_vm.guidelineFiles),function(f){return _c('tooltip',{key:f.filename,attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"fab-button px-2",attrs:{"color":"info","dark":"","small":"","aria-label":"Guideline"}},'v-btn',attrs,false),tooltip),[_c('a',{attrs:{"href":f.url,"download":"","filename":f.filename}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-file-document")])],1)])]}}],null,true)},[_c('span',[_vm._v("Guideline: "+_vm._s(f.filename))])])}),_c('tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"fab-button px-2",attrs:{"dark":"","small":"","color":"red","aria-label":"Exit"},on:{"click":function($event){$event.stopPropagation();return _vm.onFinishAnnotating($event)}}},'v-btn',attrs,false),tooltip),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-exit-to-app")])],1)]}}])},[_c('span',[_vm._v("Exit")])])],2),_c('v-progress-circular',{staticClass:"progress",attrs:{"rotate":-90,"size":50,"width":10,"value":_vm.progressPercentage,"color":_vm.taskDone ? 'success' : 'primary',"absolute":""}},[(_vm.taskDone)?_c('v-icon',{attrs:{"dark":"","color":"black"}},[_vm._v("mdi-check")]):_vm._e()],1)],1),_c('v-snackbar',{attrs:{"color":"info","right":"","bottom":"","timeout":"1500"},model:{value:(_vm.showNothingChanged),callback:function ($$v) {_vm.showNothingChanged=$$v},expression:"showNothingChanged"}},[_vm._v(" Nothing changed, skipped saving. ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }