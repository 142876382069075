




import paper from 'paper';
import Vue, { PropType } from 'vue';

const FILTER_ALPHA = 0.8;

export default Vue.extend({
  props: {
    scope: {
      type: Object as PropType<paper.PaperScope>,
      required: true
    },
    value: {
      type: Boolean,
      required: true // Panning or not
    }
  },
  created() {
    this.initialize();
  },
  data: () => ({
    tool: null as paper.Tool | null,
    prevDelta: new paper.Point(0, 0)
  }),
  methods: {
    initialize() {
      this.tool = new paper.Tool();
      this.tool.onMouseDown = (ev: paper.MouseEvent) => {
        this.prevDelta = new paper.Point(0, 0);
        this.$emit('input', true);
        ev.stopPropagation();
      };
      this.tool.onMouseDrag = (ev: paper.MouseEvent) => {
        this.doPanning(ev);
      };
      this.tool.onMouseUp = () => {
        this.$emit('input', false);
      };
    },
    doPanning(ev: paper.MouseEvent) {
      /**
       * Perform simple low-pass filtering as the delta
       * points fluctuate around origin when dragging
       */
      const delta = ev.delta;

      if (this.prevDelta) {
        const newDelta = this.prevDelta.add(
          delta.subtract(this.prevDelta).multiply(FILTER_ALPHA)
        );
        this.prevDelta = newDelta;
        this.moveCenter(newDelta.multiply(-1));
      } else {
        this.prevDelta = delta;
      }
    },
    moveCenter(delta: paper.Point) {
      this.setCenter(this.scope.view.center.add(delta));
    },
    setCenter(point: paper.Point) {
      // eslint-disable-next-line vue/no-mutating-props
      this.scope.view.center = point;
    }
  },
  beforeDestroy() {
    this.tool.remove();
    this.tool = null;
  }
});
