



import Vue from 'vue';

export default Vue.extend({
  props: {},
  created() {
    window.addEventListener('keydown', this.handleKeyDown);
  },
  destroyed() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    handleKeyDown(e: KeyboardEvent) {
      if (e.key == 'ArrowRight') {
        this.$emit('next');
        e.preventDefault();
      } else if (e.key == 'ArrowLeft') {
        this.$emit('previous');
        e.preventDefault();
      } else if (e.key == 's') {
        this.$emit('save');
        e.preventDefault();
      } else if (e.key == 'd') {
        this.$emit('next');
        e.preventDefault();
      } else if (e.key == 'a') {
        this.$emit('previous');
        e.preventDefault();
      }
    }
  }
});
