








































































import Vue, { PropType } from 'vue';
import paper from 'paper';
import _find from 'lodash/find';

import { SaveStatus, SaveStatusStrings } from '@/components/functions';
import MouseTool from './MouseTool.vue';
import MouseWheel from './MouseWheel.vue';
import {
  Choice,
  ImageClassificationParameters,
  SelectedLabelByTaskMap
} from './utils';

const ZOOM_FACTOR = 1.5;

export default Vue.extend({
  name: 'ImageClassificationView',
  components: {
    MouseWheel,
    MouseTool
  },
  props: {
    imageSrc: {
      required: true,
      type: String
    },
    config: {
      type: Object as PropType<ImageClassificationParameters>,
      required: true
    },
    selectedLabelByTask: {
      type: Object as PropType<SelectedLabelByTaskMap>,
      required: true
    },
    saveStatus: {
      type: String as PropType<SaveStatusStrings>,
      required: true
    }
  },
  mounted() {
    this.initialize();
    this.canvas = this.$refs.canvas as HTMLCanvasElement;
  },
  computed: {},
  data() {
    return {
      errorDrawing: null as string | null,
      scope: null as paper.PaperScope | null,
      imageRaster: null as paper.Raster | null,
      canvas: null as HTMLCanvasElement | null,
      panning: false,
      SaveStatus
    };
  },
  methods: {
    async initialize() {
      this.errorDrawing = null;
      try {
        await this.redraw();
      } catch (err) {
        console.error('Error initializing view', err);
        this.errorDrawing = err;
      }
    },
    handleMouseWheel(event: WheelEvent) {
      const mousePosition = new paper.Point(event.offsetX, event.offsetY);
      const viewPosition = paper.view.viewToProject(mousePosition);

      if (event.deltaY > 0) {
        console.log('Zooming out');
        this.zoomOutTo(viewPosition);
      } else {
        console.log('Zooming in');
        this.zoomInTo(viewPosition);
      }
    },
    choicesForTask(taskName: string): Choice[] {
      const task = _find(this.config.tasks, task => task.name === taskName);
      return task ? task.choices : [];
    },
    toggleChoiceIfShould(taskName: string, choice: Choice): Choice {
      const choices = this.choicesForTask(taskName);

      if (
        choices.length === 2 &&
        this.selectedLabelByTask[taskName] === choice.name
      ) {
        const newChoice = _find(
          choices,
          choice_ => choice_.name !== choice.name
        );
        console.log(
          `Choice ${choice.name} active for ${taskName}, toggling to: ${newChoice.name}`
        );
        return newChoice;
      }
      return choice;
    },
    handleChoiceClick(taskName: string, choice: Choice) {
      console.log(`Choice clicked for task '${taskName}'`, choice);

      const newChoice = this.toggleChoiceIfShould(taskName, choice);

      const selected = {
        ...this.selectedLabelByTask,
        [taskName]: newChoice.name
      };
      this.$emit('input', { labels: selected });
    },
    fitViewToImage() {
      // https://github.com/paperjs/paper.js/issues/1688
      const viewBounds = this.scope.view.bounds;
      const scaleRatio = Math.min(
        viewBounds.width / this.imageRaster.bounds.width,
        viewBounds.height / this.imageRaster.bounds.height
      );
      this.scope.view.translate(
        viewBounds.center.subtract(this.imageRaster.bounds.center)
      );
      this.scope.view.scale(scaleRatio);
    },
    async redraw() {
      const canvas = this.$refs.canvas as HTMLCanvasElement;
      if (this.scope) {
        this.imageRaster.remove();
        this.imageRaster = null;
        this.scope.project.remove();
      }
      paper.setup(canvas);
      this.scope = paper;

      await this.drawImageRaster({ source: this.imageSrc });
      this.fitViewToImage();
    },
    moveCenter(delta: paper.Point) {
      this.setCenter(this.scope.view.center.add(delta));
    },
    zoomBy(factor: number, point: paper.Point) {
      const c = this.scope.view.center;
      const p = point;
      const pc = p.subtract(c);
      const beta = 1.0 / factor;
      const a = p.subtract(pc.multiply(beta)).subtract(c);
      this.scope.view.zoom = this.scope.view.zoom / beta;
      this.setCenter(c.add(a));
    },
    zoomInTo(point: paper.Point) {
      this.zoomBy(ZOOM_FACTOR, point);
    },
    zoomOutTo(point: paper.Point) {
      this.zoomBy(1.0 / ZOOM_FACTOR, point);
    },
    setCenter(point: paper.Point) {
      this.scope.view.center = point;
    },
    async drawImageRaster({ source }): Promise<paper.Raster> {
      if (!this.scope) {
        throw Error('Expected scope to be defined');
      }
      this.imageRaster = new paper.Raster({
        source,
        position: this.scope.view.center
      });

      this.imageRaster.onDoubleClick = (ev: paper.MouseEvent) => {
        this.fitViewToImage();
        ev.stopPropagation();
      };

      return new Promise(resolve => {
        // Move top-left to origin
        this.imageRaster.onLoad = async () => {
          this.imageRaster.translate(
            new paper.Point(
              -this.imageRaster.bounds.x,
              -this.imageRaster.bounds.y
            )
          );
          resolve(this.imageRaster);
        };
      });
    }
  },
  watch: {
    imageSrc: function() {
      this.initialize();
    }
  }
});
