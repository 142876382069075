






































































































































import _indexOf from 'lodash/indexOf';
import _fromPairs from 'lodash/fromPairs';
import { mapGetters } from 'vuex';
import { computed, defineComponent, ref, toRefs } from '@vue/composition-api';

import { useSaveStatus } from '@/components/functions';
import {
  useSaving,
  useSelectedLabelByTask,
  labelListFromSelectedLabelsMap,
  useImageClassificationLabels,
  useHasUnsavedLabels,
  useInMemoryLabels
} from './functions';
import { useActiveImageSrc } from '../functions';
import { AnnotatedAsset, AnnotationGuidelineFile } from '@/types';
import LoadingModal from '../LoadingModal.vue';
import AnnotationView from './AnnotationView.vue';
import { ANNOTATION_UI_PROPS } from '@/components/annotations/props';
import KeyboardShortcuts from './KeyboardShortcuts.vue';
import Tooltip from '@/components/molecules/Tooltip.vue';
import { firstTruthy, or } from '@/utils';
import { resolveParameters } from './utils';

export default defineComponent({
  name: 'ImageClassification',
  components: {
    AnnotationView,
    LoadingModal,
    KeyboardShortcuts,
    Tooltip
  },
  props: ANNOTATION_UI_PROPS,
  setup(props) {
    const {
      layers,
      activeAsset,
      annotationConfiguration,
      fetchMedia,
      onAnnotateAsset
    } = toRefs(props);

    const assetId = computed(() => activeAsset.value.id);

    const {
      annotation: assetAnnotationFromServer,
      loading: loadingAssetAnnotation,
      error: errorLoadingAssetAnnotation,
      mutate: reloadAssetAnnotation,
      loadedForAssetId: assetAnnotationLoadedForAssetId
    } = layers.value.useAnnotation(assetId);

    const {
      annotation: assetPredictionFromServer,
      loading: loadingAssetPrediction,
      error: errorLoadingAssetPrediction,
      loadedForAssetId: assetPredictionLoadedForAssetId
    } = layers.value.usePrediction(assetId);

    const hasPreviousAssetAnnotationFromServer = computed(
      () => !!assetAnnotationFromServer.value
    );

    const { labelsFromServer } = useImageClassificationLabels({
      assetAnnotationFromServer
    });

    const uiParameters = computed(() => {
      const parameters = annotationConfiguration.value?.parameters || {};
      return resolveParameters(parameters);
    });

    const {
      inMemoryLabels: labelsInMemory,
      setInMemoryLabels
    } = useInMemoryLabels({
      assetId,
      uiParameters,
      assetAnnotationFromServer,
      assetPredictionFromServer,
      assetAnnotationLoadedForAssetId: assetAnnotationLoadedForAssetId,
      assetPredictionLoadedForAssetId: assetPredictionLoadedForAssetId
    });

    const hasPreviousAssetPredictionFromServer = computed(
      () => !!assetPredictionFromServer.value
    );

    const selectedLabelByTask = useSelectedLabelByTask({
      labels: labelsInMemory
    });

    const hasUnsavedLabels = useHasUnsavedLabels({
      labelsFromServer,
      labelsInMemory
    });

    const {
      src: activeImageSrc,
      loading: loadingImage,
      error: errorLoadingImage
    } = useActiveImageSrc(activeAsset, fetchMedia);

    const loading = or(
      loadingAssetAnnotation,
      loadingAssetPrediction,
      loadingImage
    );

    const initializing = computed(() => loading.value);

    const errorLoading = firstTruthy(
      errorLoadingAssetAnnotation,
      errorLoadingAssetPrediction,
      errorLoadingImage
    );

    const { saving, error: errorSaving, saveLabels } = useSaving({
      asset: activeAsset,
      onAnnotateAsset,
      reloadAssetAnnotation,
      labelsFromServer
    });

    const showNothingChanged = ref(false);

    async function saveInMemoryLabels() {
      console.log(`Save triggered`);
      const labelList = labelsInMemory.value;
      if (hasUnsavedLabels.value) {
        saveLabels(activeAsset.value.id, labelList);
      } else {
        console.log(`No changes, skipping save`);
        showNothingChanged.value = true;
      }
    }

    function updateSelectedLabels({
      labels
    }: {
      labels: Record<string, string>;
    }) {
      console.log(`Updating labels`, labels);
      const labelList = labelListFromSelectedLabelsMap(uiParameters, labels);
      setInMemoryLabels(labelList);
      saveInMemoryLabels();
    }

    const isInitialized = computed(() => !initializing.value);

    const saveStatus = useSaveStatus({
      loading,
      isInitialized,
      saving,
      errorSaving,
      hasUnsavedLabels,
      loadingAssetAnnotation,
      hasAnnotation: hasPreviousAssetAnnotationFromServer
    });

    return {
      assetAnnotationFromServer,
      hasPreviousAssetAnnotationFromServer,
      hasPreviousAssetPredictionFromServer,
      labelsFromServer,
      labelsInMemory,
      hasUnsavedLabels,
      activeImageSrc,
      initializing,
      errorLoading,
      errorSaving,
      loading: initializing,
      isInitialized,
      updateSelectedLabels,
      saveStatus,
      saveInMemoryLabels,
      saving,
      showNothingChanged,
      selectedLabelByTask,
      uiParameters
    };
  },
  computed: {
    ...mapGetters('auth', ['currentUser']),
    progressPercentage(): number {
      return (this.status.annotations * 100.0) / this.status.assets;
    },
    guidelineFiles(): AnnotationGuidelineFile[] {
      return this.annotationGuideline?.files || [];
    },
    taskDone(): boolean {
      return this.status.annotations === this.status.assets;
    },
    activeAssetIndex(): number {
      return _indexOf(this.assets, this.activeAsset);
    },
    assetIdToAnnotations(): Record<string, AnnotatedAsset> {
      return _fromPairs(this.annotations.map(ann => [ann.asset.id, ann]));
    }
  },
  methods: {
    nextAsset(): void {
      const index = this.activeAssetIndex;
      const newIndex = index + 1;
      if (newIndex >= this.assets.length) {
        this.onFinishAnnotating();
        return;
      }
      this.$emit('update:activeAsset', this.assets[newIndex]);
    },
    previousAsset(): void {
      const index = this.activeAssetIndex;
      const newIndex = index - 1;
      if (newIndex < 0) {
        return;
      }
      this.$emit('update:activeAsset', this.assets[newIndex]);
    },
    async onNext(): Promise<void> {
      this.nextAsset();
    },
    async onPrev(): Promise<void> {
      this.previousAsset();
    },
    onNextNotAnnotated(index: number): void {
      if (index === -1) {
        return;
      }
      this.$emit('update:activeAsset', this.assets[index]);
    }
  },
  watch: {}
});
