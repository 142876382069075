import _fromPairs from 'lodash/fromPairs';
import _omit from 'lodash/omit';
import _isEqual from 'lodash/isEqual';
import _sortBy from 'lodash/sortBy';
import {
  Ref,
  reactive,
  watch,
  toRefs,
  ref,
  computed
} from '@vue/composition-api';
import { until } from '@vueuse/core';

import {
  ImageClassificationParameters,
  SelectedLabelByTaskMap,
  ImageClassificationLabel,
  parseLabel
} from './utils';
import { Asset, AnnotatedAsset } from '@/types';

export function initializedSelectedLabelByTask(
  uiParameters: Ref<ImageClassificationParameters>
): SelectedLabelByTaskMap {
  return _fromPairs(
    uiParameters.value.tasks.map(task => {
      // Use the last available choice as default
      const choiceNames = task.choices.map(choice => choice.name);
      const lastChoiceName = choiceNames[choiceNames.length - 1];
      return [task.name, lastChoiceName];
    })
  );
}

export function useSelectedLabelByTask({
  labels
}: {
  labels: Ref<ImageClassificationLabel[]>;
}) {
  const selectedLabelByTask = computed(() => {
    return _fromPairs(labels.value.map(label => [label.feature, label.value]));
  });

  return selectedLabelByTask;
}

export const useSaving = ({
  asset,
  labelsFromServer,
  onAnnotateAsset,
  reloadAssetAnnotation
}: {
  asset: Ref<Asset>;
  labelsFromServer: Ref<ImageClassificationLabel[]>;
  onAnnotateAsset: Ref<any>;
  reloadAssetAnnotation: () => Promise<void>;
}) => {
  const state = reactive({
    saving: false,
    error: null as Error | null
  });

  async function saveLabels(
    assetId: string,
    labels: ImageClassificationLabel[]
  ): Promise<void> {
    if (assetId !== asset.value.id) {
      throw Error(`Expected asset ID to be active to save it: ${assetId}`);
    }

    const previousLabels = labelsFromServer.value;
    const previousAnnotationsWithoutTraceIds = previousLabels.map(ann =>
      _omit(ann, 'trace_id')
    );

    if (_isEqual(labels, previousAnnotationsWithoutTraceIds)) {
      console.log('Skipping saving as nothing changed');
      // this.showNothingChanged = true;
      return;
    }

    console.log('Saving new labels', labels);

    state.saving = true;
    state.error = null;
    try {
      await onAnnotateAsset.value({
        assetId,
        labels
      });
      if (assetId === asset.value.id) {
        reloadAssetAnnotation();
      }
    } catch (err) {
      state.error = err;
    } finally {
      state.saving = false;
    }
  }

  return { ...toRefs(state), saveLabels };
};

export function labelListFromSelectedLabelsMap(
  uiParameters: Ref<ImageClassificationParameters>,
  labelMap: SelectedLabelByTaskMap
): ImageClassificationLabel[] {
  const labelList = uiParameters.value.tasks.map(task => ({
    feature: task.name,
    value: labelMap[task.name]
  }));
  return labelList.filter(label => !!label.value);
}

export function parseLabels(labels: any[]): ImageClassificationLabel[] {
  return labels
    .map(label => {
      try {
        return parseLabel(label);
      } catch (err) {
        console.warn(`Ignoring invalid label`, label);
        return undefined;
      }
    })
    .filter(label => !!label);
}

export const useImageClassificationLabels = ({
  assetAnnotationFromServer
}: {
  assetAnnotationFromServer: Ref<AnnotatedAsset | null>;
}) => {
  const labelsFromServer = computed(() => {
    const assetAnnotation = assetAnnotationFromServer.value;

    if (!assetAnnotation) {
      return [];
    }

    const labels = parseLabels(assetAnnotation.labels);

    return labels;
  });

  return { labelsFromServer };
};

export const useInMemoryLabels = ({
  assetId,
  assetAnnotationFromServer,
  assetPredictionFromServer,
  assetPredictionLoadedForAssetId,
  assetAnnotationLoadedForAssetId,
  uiParameters
}: {
  assetId: Ref<string | null>;
  assetAnnotationFromServer: Ref<AnnotatedAsset | null>;
  assetPredictionFromServer: Ref<AnnotatedAsset | null>;
  assetPredictionLoadedForAssetId: Ref<string | null>;
  assetAnnotationLoadedForAssetId: Ref<string | null>;
  uiParameters: Ref<ImageClassificationParameters>;
}) => {
  const inMemoryLabels = ref([] as ImageClassificationLabel[]);

  function mutateFromServer() {
    const choiceByTask = initializedSelectedLabelByTask(uiParameters);

    const initializationAnnotation =
      assetAnnotationFromServer.value || assetPredictionFromServer.value;

    const newLabels = parseLabels(initializationAnnotation?.labels || []);

    for (const label of newLabels) {
      choiceByTask[label.feature] = label.value;
    }

    const fullLabelList = Object.keys(choiceByTask).map(feature => ({
      feature,
      value: choiceByTask[feature]
    }));

    inMemoryLabels.value = fullLabelList;
  }

  function setInMemoryLabels(labels: ImageClassificationLabel[]) {
    inMemoryLabels.value = labels;
  }

  watch(
    assetId,
    async function() {
      // Asset ID changed, need to refresh from server once those are loaded
      await until(assetAnnotationLoadedForAssetId).toBe(assetId.value);
      await until(assetPredictionLoadedForAssetId).toBe(assetId.value);

      mutateFromServer();
    },
    { immediate: true }
  );

  return { inMemoryLabels, setInMemoryLabels };
};

export const useHasUnsavedLabels = ({
  labelsFromServer,
  labelsInMemory
}: {
  labelsFromServer: Ref<ImageClassificationLabel[]>;
  labelsInMemory: Ref<ImageClassificationLabel[]>;
}) => {
  return computed(() => {
    const serverLabelsWithoutTraceIds = labelsFromServer.value.map(ann =>
      _omit(ann, 'trace_id')
    );
    const sortedServerLabels = _sortBy(serverLabelsWithoutTraceIds, 'feature');
    const sortedInMemoryLabels = _sortBy(labelsInMemory.value, 'feature');

    return !_isEqual(sortedServerLabels, sortedInMemoryLabels);
  });
};
